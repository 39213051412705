const BUSINESS_DATA_01 = {
  name: "Archie Dental",
  addressStreet: "2206 W Park Row #102",
  addressCity: "Pantego",
  addressState: "TX",
  addressZipCode: "76013",
  phone: "(817) 422-9280",
  email: "info@archiedental.com",
  facebook: "https://www.facebook.com/ArchieDental/",
  google: "https://g.page/r/CcCUQhWsTIdrEAE",
};

export { BUSINESS_DATA_01 };
