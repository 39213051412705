import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import Appointment from "../Appointment";
import Banner from "../Banner";
import { connect } from "react-redux";
import { BUSINESS_DATA_01 } from "../Data/BusinessData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

class Contact extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      language: cookies.get("language") || "eng",
      businessData1: BUSINESS_DATA_01,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language.language && language.language !== state.language) {
      return {
        language: language.language,
      };
    }

    return null;
  }
  render() {
    return (
      <React.Fragment>
        <Banner
          pageTitle={
            this.state.language === "esp" ? "Contáctenos" : "Contact Us"
          }
          language={this.state.language}
        />

        <section className="contact-us-wrapper section-padding">
          <div className="contact-information">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://maps.google.com/?q=${this.state.businessData1.addressStreet} ${this.state.businessData1.addressCity}, ${this.state.businessData1.addressState} ${this.state.businessData1.addressZipCode}`}
                  >
                    <div className="single-contact-info icon1">
                      <div className="c-icon">
                        <i className="fal fa-building" />
                      </div>
                      <div className="c-info">
                        <h4>
                          {this.state.language === "esp"
                            ? " La Dirección "
                            : "Address"}
                        </h4>
                        <p>{this.state.businessData1.addressStreet}</p>
                        <p>{`${this.state.businessData1.addressCity}, ${this.state.businessData1.addressState} ${this.state.businessData1.addressZipCode}`}</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                  <a href={`mailto:${this.state.businessData1.email}`}>
                    <div className="single-contact-info icon2">
                      <div className="c-icon">
                        <i className="fal fa-envelope" />
                      </div>
                      <div className="c-info">
                        <h4>
                          {this.state.language === "esp"
                            ? "El Correo Electrónico"
                            : "email"}
                        </h4>
                        <p>{this.state.businessData1.email}</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                  <a href={`tel:+1${this.state.businessData1.phone}`}>
                    <div className="single-contact-info icon3">
                      <div className="c-icon">
                        <i className="fal fa-phone-volume" />
                      </div>
                      <div className="c-info">
                        <h4>
                          {this.state.language === "esp"
                            ? "El Número de Teléfono"
                            : "Phone"}
                        </h4>
                        <p>{this.state.businessData1.phone}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Appointment
            title={
              this.state.language === "esp"
                ? "Ponerse en Contacto"
                : "Get In Touch"
            }
            language={this.state.language}
          />
        </section>
      </React.Fragment>
    );
  }
}

export default withCookies(connect(mapStateToProps)(Contact));
