import React, { Component } from "react";
import HeroOne from "./Hero/HeroOne";
import HeroOne2 from "./Hero/HeroOne2";
import FeaturesOne from "./Features/FeaturesOne";
import AboutOne from "./About/AboutOne";
import TeamOne from "./Team/TeamOne";
import Appointment from "./Appointment";
import GalleryOne from "./Gallery/GalleryOne";
import Promo from "./Promo";
import InsuranceOne from "./InsuranceOne";
import FeaturesData from "./Data/FeaturesData";
import TeamData from "./Data/TeamData";
import ServicePreview from "./Service/ServicePreview";
import { changeLanguage } from "../redux/ActionCreators";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import ErrorBoundary from "./ErrorBoundary";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = {
  changeLanguage: (language) => changeLanguage(language),
};

class HomeOne extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      language: cookies.get("language") || "eng",
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.language.language && props.language.language !== state.language) {
      return {
        language: props.language.language,
      };
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        <ErrorBoundary>
          <HeroOne2 language={this.state.language} />
          <FeaturesOne
            language={this.state.language}
            items={
              this.state.language === "esp"
                ? FeaturesData.items_esp
                : FeaturesData.items_eng
            }
          />
          <AboutOne language={this.state.language} />
          <ServicePreview language={this.state.language} />
          <InsuranceOne language={this.state.language} />
          <TeamOne TeamData={TeamData} language={this.state.language} />
          <Promo language={this.state.language} />
          <GalleryOne language={this.state.language} />
          <Appointment
            title={
              this.state.language === "esp"
                ? "Solicitar Cita"
                : "Request Appointment"
            }
            language={this.state.language}
          />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(HomeOne)
);
