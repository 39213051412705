import React from "react";

export default function Location(props) {
  return (
    <div
      id="location"
      className="row align-items-center justify-content-center"
    >
      <iframe
        title="Archie Dental"
        width={props.width}
        height={props.height}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=2206%20W.%20Park%20Row%20Suite%20102%20Pantego+(Archie%20Dental)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embedmaps.com/google-maps-authorization/script.js?id=367b9b7ca9a81dfe116cc0abaf117a52beab921a"
      ></script>
    </div>
  );
}
