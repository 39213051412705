export const NavBar = {
  eng: {
    home: "Home",
    services: "Services",
    photoGallery: "Photo Gallery",
    about: "About",
    contact: "Contact",
  },
  esp: {
    home: "Inicio",
    services: "Servicios",
    photoGallery: "Galería de Fotos",
    about: "Sobre",
    contact: "Contacto",
  },
};

export const AboutPage = {
  eng: {
    mission: {
      header: "Our Mission",
      text: "At Archie Dental, our mission is to provide a caring, clean and comfortable environment dedicated to providing the highest quality of dental care to all our patients. Our offices are fully equipped with state-of-the-art equipment and use the latest dental technologies to diagnose oral health conditions.  Our caring, highly experienced dentist offer a full range of preventative, restorative, and cosmetic dentistry.",
    },
  },
  esp: {
    mission: {
      header: "Nuestra Misión",
      text: "En Archie Dental nuestra misión es brindar un ambiente atento, limpio, y cómodo dedicado a brindar la más alta calidad de atención dental a todos nuestros pacientes. Nuestras oficinas están completamente equipadas con equipos de última generación y utilizan las últimas tecnologías dentales para diagnosticar afecciones de salud bucal. Nuestro dentista atento y altamente experimentado ofrece una gama completa de odontología preventiva, restauradora, y cosmética.",
    },
  },
};

export const InsurancePage = {
  eng: {
    insurance: {
      header: "Insurance?",
      text: "We accept most dental insurance plans and we bill for services through insurance. If you are curious whether your insurance covers a certain procedure or exam? Just give us a call and in a few minutes, we can tell you all that you need to know about your insurance. Let us navigate the insurance process and questions.",
    },
    noInsurance: {
      header: "No Insurance? No Problem!",
      text1:
        "Your initial exam and X-rays are FREE! We strive on transparencies and honesty every step of your dental experience.",
      text2:
        " After a comprehensive exam, our team member will walk you through the treatments that our dentist recommend and the financials that the treatments involve. So, relax and let us take care of your oral health.",
    },
  },
  esp: {
    insurance: {
      header: "¿Seguro Dental?",
      text: "Aceptamos la mayoría de los planes de seguro dental y facturamos los servicios a través de un seguro. ¿Tiene curiosidad por saber si su seguro cubre un determinado procedimiento o examen? Simplemente llámenos y en unos minutos le diremos todo lo que necesita saber sobre su seguro. Permítanos navegar por el proceso y las preguntas del seguro.",
    },
    noInsurance: {
      header: "¿Sin Seguro? ¡Ningún Problema!",
      text1:
        "¡Su examen inicial y radiografías son GRATIS! Nos esforzamos en la transparencia y la honestidad en cada paso de su experiencia dental.",
      text2:
        "Después de un examen completo, nuestro miembro del equipo lo guiará a través de los tratamientos que recomienda nuestro dentista y los aspectos económicos que implican los tratamientos. Así que relájese y déjenos cuidar su salud bucal.",
    },
  },
};
