import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import Banner from "../Banner";
import Dentist from "../Team/Dentist";
import TeamData from "../Data/TeamData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

class Team extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      language: cookies.get("language") || "eng",
    };
  }
  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language.language && language.language !== state.language) {
      return {
        language: language.language,
      };
    }

    return null;
  }
  render() {
    return (
      <React.Fragment>
        <Banner
          pageTitle={
            this.state.language === "esp"
              ? "Nuestro Equipo Dental"
              : "Our Dental Team"
          }
          language={this.state.language}
        />

        <section className="doctors-wrapper section-padding">
          <div className="container">
            <div className="row text-center">
              {TeamData.map((member) => {
                return (
                  <Dentist member={member} language={this.state.language} />
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withCookies(connect(mapStateToProps)(Team));
