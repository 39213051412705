import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { connect } from "react-redux";
import { BUSINESS_DATA_01 as BUSINESS_DATA } from "../Data/BusinessData";
import { NavBar } from "../Data/PageData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMailBulk,
  faBuilding,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { instanceOf } from "prop-types";
import getLanguageData from "../Helpers/getLanguageData";

class Navbar extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      businessData: BUSINESS_DATA,
      navBar: getLanguageData(cookies.get("language"), NavBar.eng, NavBar.esp),
      language: cookies.get("language") || "eng",
    };
  }
  changeLanguage = (event) => {
    const { cookies } = this.props;
    cookies.set("language", event.target.value, { path: "/" });
    this.props.changeLanguage(event.target.value);
    this.setState({
      language: event.target.value,
      navBar: getLanguageData(event.target.value, NavBar.eng, NavBar.esp),
    });
  };
  render() {
    let buttonText = this.state.language === "esp" ? "Reservar Ya" : "Book Now";
    return (
      <header className="header-one sticky">
        <div className="main-menu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6 col-md-5 d-flex col-5">
                <Link className="navbar-brand logo" to="/">
                  <img
                    src={require("../../assets/img/logo2.png")}
                    alt="Archie Dental"
                    width="100px"
                    height="100px"
                  />
                </Link>
                <div className="align-self-center d-none d-lg-block">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://maps.google.com/?q=${this.state.businessData.addressStreet} ${this.state.businessData.addressCity}, ${this.state.businessData.addressState} ${this.state.businessData.addressZipCode}`}
                  >
                    <span className="align-top">
                      <FontAwesomeIcon icon={faBuilding} />
                    </span>
                    <span className="businessInfo">
                      {this.state.businessData.addressStreet} <br />{" "}
                      {`${this.state.businessData.addressCity}, ${this.state.businessData.addressState} ${this.state.businessData.addressZipCode}`}
                    </span>
                  </a>

                  <br />
                  <a href={`tel:+1${this.state.businessData.phone}`}>
                    <span>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <span className="businessInfo">
                      {this.state.businessData.phone}
                    </span>
                  </a>
                  <br />
                  <a href={`mailto:${this.state.businessData.email}`}>
                    <span>
                      <FontAwesomeIcon icon={faMailBulk} />
                    </span>
                    <span className="businessInfo">
                      {this.state.businessData.email}
                    </span>
                  </a>
                </div>

                <a href="#appointment" className="align-self-center">
                  <button
                    className="btn btn-info btn-sm d-block d-lg-none"
                    style={{ height: "auto", width: 70 }}
                  >
                    {buttonText}
                  </button>
                </a>
              </div>
              <div className="col-md-7 d-none d-lg-block text-lg-right align-items-center">
                <nav id="responsive-menu" className="menu-style-one">
                  <ul className="menu-items">
                    <li>
                      <Link to="/">{this.state.navBar.home}</Link>
                    </li>
                    <li>
                      <Link to="/Services">{this.state.navBar.services}</Link>
                    </li>
                    <li>
                      <Link to="/PhotoGallery">
                        {this.state.navBar.photoGallery}
                      </Link>
                    </li>
                    <li>
                      <Link to="/About">{this.state.navBar.about}</Link>
                    </li>
                    <li>
                      <Link to="/Contact">{this.state.navBar.contact}</Link>
                    </li>
                  </ul>
                </nav>
                {this.state.businessData.instagram ? <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.businessData.instagram}
                >
                  <FontAwesomeIcon
                    className="fa-icon"
                    size="lg"
                    icon={faInstagram}
                  />
                </a> : ""}
                
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.businessData.facebook}
                >
                  <FontAwesomeIcon
                    className="fa-icon"
                    size="lg"
                    icon={faFacebook}
                  />
                </a>
                {/*  <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://twitter.com/"
                >
                  <FontAwesomeIcon
                    className="fa-icon"
                    size="lg"
                    icon={faTwitter}
                  />
                </a> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.businessData.google}
                >
                  <FontAwesomeIcon
                    className="fa-icon"
                    style={{ marginRight: 30 }}
                    size="lg"
                    icon={faGoogle}
                  />
                </a>
                <select
                  className="form-select mr-4"
                  aria-label="Default select example"
                  value={this.state.language}
                  onChange={(event) => this.changeLanguage(event)}
                >
                  <option value="eng">English</option>
                  <option value="esp">Español</option>
                </select>
                <a href="#appointment">
                  <button className="btn btn-info btn-lg d-none d-lg-inline mt-n4">
                    {buttonText}
                  </button>
                </a>
              </div>
              <div className="col-6 d-block d-lg-none align-self-center">
                <select
                  className="form-select d-block d-lg-none mt-5"
                  aria-label="Default select example"
                  value={this.state.language}
                  onChange={(event) => this.changeLanguage(event)}
                >
                  <option value="eng">English</option>
                  <option value="esp">Español</option>
                </select>
                <nav className="navbar navbar-expand-lg text-right navbar-light mobile-nav">
                  <button
                    className="navbar-toggler"
                    style={{ border: "1px solid #D3D3D3" }}
                    type="button"
                    data-toggle="collapse"
                    data-target="#mobilenav"
                  >
                    <span className="fal fa-bars hamburger-menu" />
                  </button>
                </nav>
              </div>
              <div
                className="collapse navbar-collapse mobile-menu ml-auto"
                id="mobilenav"
                data-toggle="collapse"
                data-target="#mobilenav"
              >
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      {this.state.navBar.home}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/Services">
                      {this.state.navBar.services}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/PhotoGallery">
                      {this.state.navBar.photoGallery}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/About">
                      {this.state.navBar.about}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/Contact">
                      {this.state.navBar.contact}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withCookies(Navbar);
