const FooterData = {
  singleWidget_eng: [
    {
      id: 1,
      title: "Information",
      menuItems: [
        {
          id: 1,
          url: "/About",
          title: "About",
        },
        {
          id: 2,
          url: "/About#location",
          title: "Our Location",
        },
        /*     {
          id: 3,
          url: ".#",
          title: "FAQ’s",
        }, */
        {
          id: 4,
          url: "/Contact",
          title: "Contact Us",
        },
      ],
    },
    {
      id: 2,
      title: "Resources",
      menuItems: [
        {
          id: 1,
          url: ".#appointment",
          title: "New Patients",
        },
        {
          id: 2,
          url: "/Team",
          title: "Our Team",
        },
        /* {
          id: 3,
          url: ".#",
          title: "Patient Form",
        }, */
        {
          id: 4,
          url: "/.#insuranceSection",
          title: "Insurance",
        },
        /* {
          id: 5,
          url: ".#",
          title: "Account Login",
        }, */
      ],
    },
  ],
  singleWidget_esp: [
    {
      id: 1,
      title: "Información",
      menuItems: [
        {
          id: 1,
          url: "/About",
          title: "Sobre",
        },
        {
          id: 2,
          url: "/About#location",
          title: "Nuestra Ubicación",
        },
        /*    {
          id: 3,
          url: ".#",
          title: "FAQ’s",
        }, */
        {
          id: 4,
          url: "/Contact",
          title: "Contáctenos",
        },
      ],
    },
    {
      id: 2,
      title: "Recursos",
      menuItems: [
        {
          id: 1,
          url: ".#appointment",
          title: "Nuevos Pacientes",
        },
        {
          id: 2,
          url: "/Team",
          title: "Nuestro Equipo",
        },
        /* {
          id: 3,
          url: ".#",
          title: "Patient Form",
        }, */
        {
          id: 4,
          url: "/.#insuranceSection",
          title: "Seguro",
        },
        /* {
          id: 5,
          url: ".#",
          title: "Account Login",
        }, */
      ],
    },
  ],

  copyright: `Archie Dental - ${new Date().getFullYear()}`,
};

export default FooterData;
