const BeforeAfterData = [
  {
    id: 1,
    before: '01_before.jpg',
    after: '01_after.jpg',
  },
  {
    id: 2,
    before: '02_before.jpg',
    after: '02_after.jpg',
  },
  {
    id: 3,
    before: '03_before.jpeg',
    after: '03_after.png',
  },
  {
    id: 4,
    before: '04_before.jpg',
    after: '04_after.jpg',
  },
  {
    id: 5,
    before: '05_before.jpg',
    after: '05_after.jpg',
  },
  {
    id: 6,
    before: '06_before.jpg',
    after: '06_after.jpg',
  },
  {
    id: 7,
    before: '07_before.jpg',
    after: '07_after.jpg',
  },
  {
    id: 8,
    before: '08_before.jpg',
    after: '08_after.jpg',
  },
  {
    id: 9,
    before: '09_before.jpg',
    after: '09_after.jpg',
  },
  {
    id: 10,
    before: '10_before.jpg',
    after: '10_after.jpg',
  },
  {
    id: 11,
    before: '11_before.jpg',
    after: '11_after.jpg',
  },
  {
    id: 12,
    before: '12_before.jpg',
    after: '12_after.jpg',
  },
  {
    id: 13,
    before: '13_before.jpg',
    after: '13_after.jpg',
  },
  {
    id: 14,
    before: '14_before.jpg',
    after: '14_after.jpg',
  },
  {
    id: 15,
    before: '15_before.jpg',
    after: '15_after.jpg',
  },
];

export default BeforeAfterData;
