import React, { Component } from "react";

function HeroOne2(props) {
    return (
      <section className="hero-slider-wrapper home-one-slider">
        <div className="single-hero-slide text-white hero-slide1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 col-md-10">
                <div className="hero-slide-left">
                  <h2>{props.language === "esp" ? "Bienvenido a" : "Welcome to"}</h2>
                  <h1>Archie Dental</h1>
                  <p>{props.language === "esp" ? "Únase a nosotros para un ambiente dental profesional y amigable. ¡Nuestros profesionales están dedicados a darte la sonrisa que te mereces!" : "Join us for a professional and friendly dental environment. Our professionals are dedicated to giving you the smile that you deserve!"}
                  </p>
                  <a href="#appointment" className="theme-btn fill-btn">
                    {props.language === "esp" ? "Cita" : "Appointment"}
                  </a>
                  <a href="#about" className="theme-btn btn_black">
                    {props.language === "esp" ? "Aprende más" : "Learn More"}
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12 col-md-10 offset-md-2 offset-lg-0">
                <div className="hero-slide-right">
                  <div className="animate-img">
                    <img
                      className="aimg1"
                      src={require("../../assets/img/woman-brush.png")}
                      alt=""
                    />
                    <img
                      className="aimg2"
                      src={require("../../assets/img/doctorfinding.png")}
                      alt=""
                    />
                  </div>
                  <img
                    src={require("../../assets/img/hero-theeth.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default HeroOne2;
