import React, { Component } from "react";
import emailjs from "emailjs-com";
import { ToggleButton, ButtonGroup, Toast } from "react-bootstrap";
import getLanguageData from "./Helpers/getLanguageData";
import * as Sentry from "@sentry/react";

const {
  REACT_APP_EMAILJS_TEMPLATE_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_USER_ID,
} = process.env;

const validation = {
  errors_eng: {
    name: "Please enter your name.",
    phone: "Please enter your phone number.",
    email: "Please enter your email.",
    subject: "Please enter a subject.",
    patientType: "Please select your patient type.",
    message: "Please enter your message.",
  },
  errors_esp: {
    name: "Por favor introduzca su nombre.",
    phone: "Por favor introduzca su número de teléfono.",
    email: "Por favor introduzca su correo electrónico.",
    subject: "Por favor introduce un tema.",
    patientType: "Por favor seleccione su tipo de paciente.",
    message: "Por favor introduzca su mensaje.",
  },
};

class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "eng",
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      checked: false,
      patientType: "",
      show: false,
      errors: {},
    };
  }

  myChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  myChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  myChangePhone = (event) => {
    let phoneNumber = event.target.value.replace(/[^\d]/g, "");

    if (phoneNumber.length > 10) {
      return;
    }

    //check if number length equals to 10
    if (phoneNumber.length === 10) {
      //reformat and return phone number
      phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      this.setState({
        phone: phoneNumber,
      });
    } else {
      this.setState({
        phone: event.target.value,
      });
    }
  };

  myChangeSubject = (event) => {
    this.setState({
      subject: event.target.value,
    });
  };

  myChangeMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  myChangeRadio = (event) => {
    this.setState({
      patientType: event.target.value,
    });
  };

  validateForm = () => {
    let errorMessages = getLanguageData(
      this.props.language,
      validation.errors_eng,
      validation.errors_esp
    );
    let errors = {};
    let isValid = true;

    if (!this.state.name) {
      errors["name"] = errorMessages.name;
      isValid = false;
    } else {
      errors["name"] = "";
    }

    if (!this.state.phone) {
      errors["phone"] = errorMessages.phone;
      isValid = false;
    }

    if (!this.state.email) {
      errors["email"] = errorMessages.email;
      isValid = false;
    } else {
      errors["email"] = "";
    }

    if (!this.state.subject) {
      errors["subject"] = errorMessages.subject;
      isValid = false;
    } else {
      errors["subject"] = "";
    }

    if (!this.state.patientType) {
      errors["patientType"] = errorMessages.patientType;
      isValid = false;
    } else {
      errors["patientType"] = "";
    }

    if (!this.state.message) {
      errors["message"] = errorMessages.message;
      isValid = false;
    } else {
      errors["message"] = "";
    }

    this.setState({ errors: errors });

    return isValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      emailjs
        .sendForm(
          `${REACT_APP_EMAILJS_SERVICE_ID}`,
          `${REACT_APP_EMAILJS_TEMPLATE_ID}`,
          event.target,
          `${REACT_APP_EMAILJS_USER_ID}`
        )
        .then(
          (result) => {
            this.setState({ show: true });
            this.clearForm();
          },
          (error) => {
            Sentry.captureException(error);
          }
        );
    }
  };

  clearForm = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      checked: false,
      patientType: "",
      errors: {},
    });
  };

  static getDerivedStateFromProps(props, state) {
    const { language } = props;
    if (language && language !== state.language) {
      return {
        language: language,
        errors: {},
      };
    }

    return null;
  }

  render() {
    return (
      <section id="appointment" className="appointment-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <div className="appointment-form">
                <div className="section-title-one">
                  <h1>
                    {this.props.title
                      ? this.props.title
                      : "Request Appointment"}
                  </h1>
                </div>
                <form className="row" onSubmit={this.handleSubmit}>
                  <div className="col-lg-6 col-md-6 col-12">
                    <input
                      className="appointmentInput"
                      type="text"
                      placeholder={
                        this.props.language === "esp" ? "Nombre" : "Name"
                      }
                      name="name"
                      value={this.state.name}
                      onChange={this.myChangeName}
                    />
                    <div className="invalid-feedback d-block">
                      {this.state.errors.name}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <input
                      type="email"
                      className="appointmentInput"
                      placeholder={
                        this.props.language === "esp"
                          ? "Dirección de Correo Electrónico"
                          : "Email Address"
                      }
                      name="email"
                      value={this.state.email}
                      onChange={this.myChangeEmail}
                    />
                    <div className="invalid-feedback d-block">
                      {this.state.errors.email}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <input
                      type="text"
                      className="appointmentInput"
                      placeholder={
                        this.props.language === "esp"
                          ? "Número de Teléfono"
                          : "Phone Number"
                      }
                      name="phone"
                      value={this.state.phone}
                      onChange={this.myChangePhone}
                    />
                    <div className="invalid-feedback d-block">
                      {this.state.errors.phone}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <input
                      type="text"
                      className="appointmentInput"
                      placeholder={
                        this.props.language === "esp" ? "Tema" : "Subject"
                      }
                      name="subject"
                      value={this.state.subject}
                      onChange={this.myChangeSubject}
                    />
                    <div className="invalid-feedback d-block">
                      {this.state.errors.subject}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <ButtonGroup
                      style={{
                        marginTop: 20,
                        marginLeft: 20,
                      }}
                    >
                      <ToggleButton
                        type="radio"
                        name="patientType"
                        variant="outline-info"
                        value="New Patient"
                        checked={this.state.patientType === "New Patient"}
                        onChange={this.myChangeRadio}
                      >
                        {this.props.language === "esp"
                          ? "Paciente Nuevo"
                          : "New Patient"}
                      </ToggleButton>
                      <ToggleButton
                        type="radio"
                        name="patientType"
                        variant="outline-info"
                        value="Returning Patient"
                        checked={this.state.patientType === "Returning Patient"}
                        onChange={this.myChangeRadio}
                      >
                        {this.props.language === "esp"
                          ? "Paciente de Vuelta"
                          : "Returning Patient"}
                      </ToggleButton>
                    </ButtonGroup>
                    <div className="invalid-feedback d-block">
                      {this.state.errors.patientType}
                    </div>
                  </div>
                  <div className="col-12">
                    <textarea
                      className="appointmentInput"
                      value={this.state.message}
                      onChange={this.myChangeMessage}
                      name="message"
                    />
                    <div className="invalid-feedback d-block">
                      {this.state.errors.message}
                    </div>
                  </div>
                  <button className="submit-btn" type="submit">
                    {this.props.language === "esp"
                      ? "Enviar la Solicitud"
                      : "Send Request"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="appointment-right-banner">
          <img src={require("../assets/img/appointment.png")} alt="donto" />
        </div>
        <div className="row">
          <div className="col">
            <Toast
              onClose={() => this.setState({ show: false })}
              show={this.state.show}
              delay={5000}
              autohide
            >
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">
                  {this.props.language === "esp"
                    ? "¡La Forma de Contacto fue Mandada!"
                    : "Contact Form Sent!"}
                </strong>
              </Toast.Header>
              <Toast.Body>
                {this.props.language === "esp"
                  ? "¡Gracias por iniciar contacto con nosotros!"
                  : "Thank you for reaching out to us!"}
              </Toast.Body>
            </Toast>
          </div>
        </div>
      </section>
    );
  }
}

export default Appointment;
